
import { defineComponent } from 'vue'

// Utils
import utilities from "../utilities";

export default defineComponent({
    name: "Faq",
    data() {
        return {
            questions: [] as any[any]
        }
    },
    mounted() {
        //get order
        let questions = utilities.getItems('help') as any
        Promise.resolve(questions).then((data: any[any]) => {
            this.questions = data
        })
    }
})
